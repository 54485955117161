<template>
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3960.6310571214767!2d3.2225255115229574!3d6.934623193662663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sOremeji%2C%20iyana%20Camp%20Area%2C%20%20KM%2038%2C%20Lagos-Abeokuta%20Expressway%2C%20Itori%2C%20EWEKORO.!5e0!3m2!1sen!2sng!4v1643463848508!5m2!1sen!2sng"
    width="100%"
    height="400"
    style="border: 0"
    allowfullscreen=""
    loading="lazy"
  ></iframe>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
